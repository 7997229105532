<template>
  <div class="container">
    <!-- <v-row class="">
      <v-col cols="12" class="top-panel">
        <div class="arrow-btn">
          <img
            src="../assets/img/arrowBack.svg"
            class="img-pluss-btn"
            alt="arrow-back"
          />
        </div>
        <div class="vertical-line-1"></div>
        <div class="add-btn">
          <img src="../assets/img/plusAdd.svg" alt="pluss" />
          <p class="add-text">Добавить</p>
        </div>
        <div class="vertical-line"></div>
      </v-col>
    </v-row> -->
    <!-- <v-row class="">
      <v-col cols="12" class="search-container">
        <h1 class="page-title">Рестораны</h1>
        <div class="search-container-input">
          <template>
            <v-text-field
              v-model="searchQuery"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              clearable
            ></v-text-field>
          </template>
        </div> </v-col
    ></v-row> -->
    <RestaurantPage></RestaurantPage>
  </div>
</template>


<style scoped>
::v-deep .v-input__slot {
  border-style: none;
  margin-bottom: 0px;
}

.search-container-input {
  width: 100%; /* Элемент будет занимать всю доступную ширину контейнера */
  max-width: 607px; /* Максимальная ширина 607px */

  /* Дополнительные стили по вашему усмотрению */
}
@media (max-width: 1200px) {
  .search-container-input {
    width: 50%; /* Например, уменьшаем ширину до 90% */
  }
}
.search-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img-pluss-btn {
  margin-left: 10px;
}
.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .top-panel .container {
  width: 100%;
  padding: 0px !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
} */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}
.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}
.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}
.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black; /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}
.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
}
.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}
</style>


<script>
// import RestaurantsTable from "../components/RestaurantsTable.vue";
import RestaurantPage from "../views/RestaurantPage.vue";
export default {
  components: { RestaurantPage },
  name: "Restaurants",
  data() {
    return {
      searchQuery: "",
    };
  },
};
</script>




<style lang="scss">
// .container {
//   width: 100%;
//   padding: 0px;
//   margin-right: auto;
//   margin-left: auto;
// }
@media (min-width: 1264px) {
  .container {
    //1185px дефолтное название если что то ломаться начнется на маленьких мониторах
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  //900 дефолтное название если что то ломаться начнется на маленьких мониторах
  .container {
    max-width: 100% !important;
  }
}
</style>